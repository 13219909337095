// src/router.js
import { createRouter, createWebHistory } from "vue-router";
import BankExportImport from "@/components/BankExportImport.vue";
import ManageObjects from "@/components/ManageObjects.vue";
import LandingPage from "@/components/LandingPage.vue";
import ManageUnits from "@/components/ManageUnits.vue";
import RenterComponent from "@/components/RenterComponent.vue";
import PaymentOverview from "@/components/PaymentOverview.vue";

// Definiere deine Routen
const routes = [
  { path: "/", component: LandingPage },
  { path: "/bank-export-import", component: BankExportImport },
  { path: "/manage-objects", component: ManageObjects },
  { path: "/manage-units", component: ManageUnits },
  { path: "/renter", component: RenterComponent },
  { path: "/payment-overview", component: PaymentOverview },
];

// Erstelle den Router
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

<template>
  <div class="main">
    <form @submit.prevent="onFormSubmit">
      <div>
        <label for="forename">Vorname</label>
        <input id="forename" type="text" v-model="formData.forename" />
      </div>
      <div>
        <label for="forename2">Zweiter Vorname</label>
        <input id="forename2" type="text" v-model="formData.forename2" />
      </div>
      <div>
        <label for="lastname">Nachname</label>
        <input id="lastname" type="text" v-model="formData.lastname" />
      </div>
      <button type="submit">senden</button>
    </form>
    <button v-on:click="loadData">Objekte Laden</button>
    <table v-if="serverUnits.length !== 0">
      <thead>
        <tr>
          <th>id</th>
          <th>Vorname</th>
          <th>2. Vorname</th>
          <th>Nachname</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="renter in serverRenterList" :key="renter.id">
          <td>{{ renter.id }}</td>
          <td>
            {{ renter.forename }}
          </td>
          <td>
            {{ renter.forename2 }}
          </td>
          <td>
            {{ renter.lastname }}
          </td>
          <!-- <td>
            <button @click="assignPayment(renter.id)">
              Zahlungen einsehen
            </button>
          </td> -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import apiClient from "@/plugins/axios";

// Reaktive Daten
const formData = ref({
  forename: undefined,
  forename2: undefined,
  lastname: undefined,
});

const serverRenterList = ref([]);
const serverUnits = ref([]);

function assignPayment(data) {
  console.log(data);
}

// Funktionen
function loadData() {
  apiClient.get("renter").then((response) => {
    serverUnits.value = response.data;
  });
}

function onFormSubmit() {
  console.log("Form submitted", formData.value);

  apiClient
    .post("renter", formData.value)
    .then((response) => {
      //   console.log("Server response:", response.data);
    })
    .catch((error) => {
      //   console.error("Error submitting form:", error);
    });
}

// onMounted Hook
onMounted(() => {
  apiClient.get("renter").then((data) => {
    serverRenterList.value = data.data;
  });
});
</script>

<style scoped>
form div {
  margin: auto;
  max-width: 500px;
  text-align: left;
  display: flex;
}

label {
  width: 150px;
  display: inline-block;
}

input {
  display: inline-block;
  position: relative;
  flex: 1;
}
</style>

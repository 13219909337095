<template>
  <div class="main">
    <form @submit.prevent="onFormSubmit">
      <div>
        <label for="description">Beschreibung</label>
        <input id="description" type="text" v-model="formData.description" />
      </div>
      <div>
        <label for="place">Ort</label>
        <input id="place" type="text" v-model="formData.place" />
      </div>
      <div>
        <label for="zip">PLZ</label>
        <input id="zip" type="text" v-model="formData.zip" />
      </div>
      <div>
        <label for="zip">Straße</label>
        <input id="street" type="text" v-model="formData.street" />
      </div>
      <div>
        <label for="houseNr">Hausnummer</label>
        <input id="houseNr" type="text" v-model="formData.houseNr" />
      </div>

      <button type="submit">senden</button>
    </form>

    <button v-on:click="loadData">Objekte Laden</button>
    <table v-if="serverObjects.length !== 0">
      <thead>
        <tr>
          <th>Beschreibung</th>
          <th>PLZ</th>
          <th>Ort</th>
          <th>Straße</th>
          <th>Nummer</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="rentObject in serverObjects" :key="rentObject.id">
          <td>{{ rentObject.description }}</td>
          <td>{{ rentObject.zip }}</td>
          <td>{{ rentObject.place }}</td>
          <td>{{ rentObject.street }}</td>
          <td>{{ rentObject.houseNr }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import apiClient from "@/plugins/axios";

export default {
  name: "ManageObjects",
  data() {
    return {
      formData: {
        description: "",
        street: "",
        houseNr: "",
        zip: "",
        place: "",
      },
      serverObjects: [],
    };
  },
  methods: {
    onFormSubmit() {
      // Hier kannst du die Werte aus dem Formular abrufen
      console.log("Form submitted", this.formData);

      // Beispiel: Daten an den Server senden
      apiClient
        .post("rent-object", this.formData)
        .then((response) => {
          console.log("Server response:", response.data);
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        });
    },
    loadData() {
      apiClient.get("rent-object").then((response) => {
        this.serverObjects = response.data;
      });
    },
  },
};
</script>

<style scoped>
form div {
  margin: auto;
  max-width: 500px;
  text-align: left;
  display: flex;

  label {
    width: 150px;
    display: inline-block;
  }
  input {
    display: inline-block;
    position: relative;
    flex: 1;
  }
}
</style>

<template>
  <div class="main">
    <form @submit.prevent="onFormSubmit">
      <div>
        <label for="description">Beschreibung</label>
        <input id="description" type="text" v-model="formData.description" />
      </div>
      <div>
        <label for="rent-object">Mietobjekt</label>
        <select
          name="rent-object"
          id="rent-object"
          v-model="formData.rentObject"
          style="width: 70%"
        >
          <option
            v-for="rentObject in serverRentObjects"
            :key="rentObject.id"
            :value="rentObject.id"
          >
            {{ rentObject.description }}
          </option>
        </select>
      </div>
      <button type="submit">senden</button>
    </form>
    <button v-on:click="loadData">Units Laden</button>
    <table v-if="serverUnits.length !== 0">
      <thead>
        <tr>
          <th>Beschreibung</th>
          <th>Mietobjekt</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="unit in serverUnits" :key="unit.id">
          <td>{{ unit.description }}</td>
          <td>
            {{
              serverRentObjects.find(
                (rentObject) => rentObject.id === unit.rentObject
              ).description
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import apiClient from "@/plugins/axios";

// Reaktive Daten
const formData = ref({
  description: "",
  rentObject: undefined,
  renter: undefined,
});

const serverRentObjects = ref([]);
const serverUnits = ref([]);

// Funktionen
function loadData() {
  apiClient.get("unit").then((response) => {
    serverUnits.value = response.data;
  });
}

function onFormSubmit() {
  console.log("Form submitted", formData.value);

  apiClient
    .post("unit", formData.value)
    .then((response) => {
      //   console.log("Server response:", response.data);
    })
    .catch((error) => {
      //   console.error("Error submitting form:", error);
    });
}

// onMounted Hook
onMounted(() => {
  console.log("activated");
  apiClient.get("rent-object").then((data) => {
    serverRentObjects.value = data.data;
  });
});
</script>

<style scoped>
form div {
  margin: auto;
  max-width: 500px;
  text-align: left;
  display: flex;
}

label {
  width: 150px;
  display: inline-block;
}

input {
  display: inline-block;
  position: relative;
  flex: 1;
}
</style>

import axios from "axios";

// Erstelle eine Instanz von Axios mit globalen Einstellungen
const apiClient = axios.create({
  baseURL: "https://test.jsmedien.de/api", // process.env.VUE_APP_API_URL || "https://test.jsmedien.de/api", // "http://localhost:3000", // Standard-URL für alle Anfragen
  withCredentials: false, // Falls du Cookies oder andere Authentifizierungsmethoden verwendest
  headers: {
    "Content-Type": "application/json",
  },
});

export default apiClient;

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router"; // Importiere den Router

// Bootstrap CSS importieren
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap JS importieren
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Enthält auch Popper.js

const app = createApp(App);
app.use(router);
app.mount("#app");

<template>
  <h1>Immohub</h1>
  <div>Dies ist eine erste Version von Immohub.</div>
  <div>Weder das aussehen noch die Funktion ist fertiggestellt</div>
  <div>
    Zunächst ist nur die händische und ungeprüfte eingabe von Daten möglich.
    <br />
    Falls Daten nicht wie erwartet eingegeben werden kann die Seite
    abstürzen,<br />
    dabei hilft bis jetzt nur das neuladen.<br />
  </div>
</template>

<script>
export default {
  name: "LandingPage",
  data() {
    return {
      fileContent: "", // Hier wird der konvertierte Inhalt gespeichert
      serverBankData: [],
    };
  },
  methods: {},
};
</script>

<style scoped></style>

<template>
  <div class="main">
    <h1>Zahlungsübersicht</h1>
    <!-- <button v-on:click="loadData">Objekte Laden</button> -->
    <div class="select-user">
      <label for="renter">Mieter</label>
      <select
        name="renter"
        id="renter"
        v-model="selectedRenter"
        class="form-select"
      >
        <option value=""></option>
        <option
          v-for="renter in renterList"
          :key="renter.id"
          :value="renter.id"
        >
          {{ `${renter.lastname}, ${renter.forename}` }}
        </option>
      </select>
    </div>
    <div>
      <table v-if="!!payments && payments?.length > 0">
        <tbody>
          <tr>
            <th>Betrag</th>
            <th>Wertstellung</th>
            <th>Verwendungszweck</th>
          </tr>
          <tr v-for="payment in payments" :key="payment.id">
            <td>{{ payment.bankExportLine.amount }}€</td>
            <td>
              {{
                format(new Date(payment.bankExportLine.valueDate), "dd.MM.yyyy")
              }}
            </td>
            <td>{{ payment.bankExportLine.purpose }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import apiClient from "@/plugins/axios";
import { format } from "date-fns";

const renterList = ref([]);
const selectedRenter = ref(undefined);
const payments = ref([]);

watch(
  () => selectedRenter.value,
  async (newValue) => {
    if (newValue) {
      const response = await apiClient.get("payment/" + newValue);
      payments.value = response.data;
    } else {
      payments.value = [];
    }
    console.log(newValue, payments);
  }
);

function assignPayment(data) {
  console.log(data);
}

// Funktionen
function loadData() {
  apiClient.get("renter").then((response) => {
    renterList.value = response.data;
  });
}

// onMounted Hook
onMounted(() => {
  loadData();
});
</script>

<style scoped>
div.select-user {
  display: flex;
  label {
    vertical-align: middle;
    line-height: 2.5em;
    margin-right: 1rem;
  }
  label,
  select {
    display: inline-block;
  }
}
</style>

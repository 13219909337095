<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <router-link class="navbar-brand" to="/">Immohub</router-link>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link class="nav-link" to="/">Home</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/bank-export-import"
            >Bank Export importieren</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/manage-objects"
            >Objekte verwalten</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/manage-units"
            >Units verwalten</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/renter"
            >Mieter verwalten</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/payment-overview"
            >Zahlungsübersicht</router-link
          >
        </li>
      </ul>
    </div>
  </nav>
  <div class="content">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "MainComponent",
};
</script>

<style scoped>
div.content {
  width: 80vw;
  max-width: 1200px;
  margin: auto;
}
</style>

<template>
  <div class="main">
    <table style="margin: auto; text-align: left">
      <tbody>
        <tr>
          <td><button @click="loadData">Daten laden</button></td>
          <td>Daten vom Server laden und anzeigen</td>
        </tr>
        <tr>
          <td><input type="file" @change="onFileChange" /></td>
          <td>Daten hochladen</td>
        </tr>
      </tbody>
    </table>
    <div class="file-content">
      <div
        style="display: flex; border: solid #aaa 1px"
        v-if="serverBankData && serverBankData.length > 0"
      >
        <div style="width: 100px; text-align: left">id</div>
        <div style="width: 140px; text-align: left">BuchungsId</div>
        <div style="width: 150px; text-align: left">Wertstellung</div>
        <div style="width: 600px; text-align: left">Name</div>
        <div style="width: 100px; text-align: right">Betrag</div>
        <div style="width: 400px; text-align: right">Verwendungszweck</div>
        <div style="width: 400px; text-align: center">Mieter</div>
      </div>

      <div v-for="bankData in serverBankData" :key="bankData.id">
        <div style="display: flex; border: solid #aaa 1px">
          <div style="width: 100px; text-align: left">{{ bankData.id }}</div>
          <div style="width: 140px; text-align: left">
            {{ bankData.bookingID }}
          </div>
          <div style="width: 150px; text-align: left">
            {{
              formatDate(
                bankData.valueDate ??
                  bankData.bookingDate ??
                  bankData.bookingID?.substr(4, 2) +
                    ("." + bankData.bookingID?.substr(6, 2)) +
                    ("." + bankData.bookingID?.substr(0, 4))
              )
            }}
          </div>
          <div style="width: 600px; text-align: left">{{ bankData.name1 }}</div>
          <div style="width: 100px; text-align: right">
            {{ bankData.amount }}€
          </div>
          <div style="width: 400px; text-align: right">
            {{ bankData.purpose }}
          </div>
          <div style="width: 400px; text-align: right">
            <select :id="`renter_for_${bankData.id}`" v-model="bankData.renter">
              <option :value="undefined"></option>
              <option
                v-for="current in renter"
                :key="current.id"
                :value="current.id"
              >
                {{ current.forename }}
              </option>
            </select>
            <button
              @click="
                () => {
                  assignRenter(bankData);
                }
              "
            >
              Zuordnen
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, Ref } from "vue";
import apiClient from "@/plugins/axios";

const fileContent: Ref<string> = ref("");
const serverBankData: Ref<any[]> = ref([]);
const renter: Ref<any[]> = ref([]);

function formatDate(date: any) {
  if (!date) return "";
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
}

async function loadData() {
  renter.value = (await apiClient.get("renter")).data;

  console.log(renter.value);

  const bankExportData: { data: any[] } = await apiClient.get("bank-export");
  serverBankData.value = bankExportData.data
    .map((current) => {
      current.renter = current.payment?.renter?.id;
      return current;
    })
    .sort((a: any, b: any) => a.bookingID - b.bookingID);
}

async function assignRenter(bankExport: any) {
  await apiClient.put(
    "bank-export/assign-renter/" + bankExport.id + "/" + bankExport.renter,
    {
      renter: bankExport.renter,
    }
  );
  console.log("renter: ", bankExport.renter);
}

function onFileChange(event: any) {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();

    // Datei als ArrayBuffer lesen
    reader.onload = async (e) => {
      const arrayBuffer = e.target?.result;

      if (!arrayBuffer) return;

      // ANSI-Decoder (Windows-1252)
      const decoder = new TextDecoder("windows-1252");
      const text = decoder.decode(arrayBuffer as ArrayBuffer);

      // Text kann jetzt direkt angezeigt oder weiterverarbeitet werden
      fileContent.value = text;
      await apiClient.post("file-upload", { content: text });
    };

    // Fehlerbehandlung
    reader.onerror = (error) => {
      console.error("Error reading file:", error);
    };

    // Lese die Datei als ArrayBuffer (um die Byte-Daten zu bekommen)
    reader.readAsArrayBuffer(file);
  }
}
</script>

<style scoped></style>

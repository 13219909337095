import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "main" }
const _hoisted_2 = { style: {"margin":"auto","text-align":"left"} }
const _hoisted_3 = { class: "file-content" }
const _hoisted_4 = {
  key: 0,
  style: {"display":"flex","border":"solid #aaa 1px"}
}
const _hoisted_5 = { style: {"display":"flex","border":"solid #aaa 1px"} }
const _hoisted_6 = { style: {"width":"100px","text-align":"left"} }
const _hoisted_7 = { style: {"width":"140px","text-align":"left"} }
const _hoisted_8 = { style: {"width":"150px","text-align":"left"} }
const _hoisted_9 = { style: {"width":"600px","text-align":"left"} }
const _hoisted_10 = { style: {"width":"100px","text-align":"right"} }
const _hoisted_11 = { style: {"width":"400px","text-align":"right"} }
const _hoisted_12 = { style: {"width":"400px","text-align":"right"} }
const _hoisted_13 = ["id", "onUpdate:modelValue"]
const _hoisted_14 = ["value"]
const _hoisted_15 = ["onClick"]

import { ref, onMounted, Ref } from "vue";
import apiClient from "@/plugins/axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'BankExportImport',
  setup(__props) {

const fileContent: Ref<string> = ref("");
const serverBankData: Ref<any[]> = ref([]);
const renter: Ref<any[]> = ref([]);

function formatDate(date: any) {
  if (!date) return "";
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
}

async function loadData() {
  renter.value = (await apiClient.get("renter")).data;

  console.log(renter.value);

  const bankExportData: { data: any[] } = await apiClient.get("bank-export");
  serverBankData.value = bankExportData.data
    .map((current) => {
      current.renter = current.payment?.renter?.id;
      return current;
    })
    .sort((a: any, b: any) => a.bookingID - b.bookingID);
}

async function assignRenter(bankExport: any) {
  await apiClient.put(
    "bank-export/assign-renter/" + bankExport.id + "/" + bankExport.renter,
    {
      renter: bankExport.renter,
    }
  );
  console.log("renter: ", bankExport.renter);
}

function onFileChange(event: any) {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();

    // Datei als ArrayBuffer lesen
    reader.onload = async (e) => {
      const arrayBuffer = e.target?.result;

      if (!arrayBuffer) return;

      // ANSI-Decoder (Windows-1252)
      const decoder = new TextDecoder("windows-1252");
      const text = decoder.decode(arrayBuffer as ArrayBuffer);

      // Text kann jetzt direkt angezeigt oder weiterverarbeitet werden
      fileContent.value = text;
      await apiClient.post("file-upload", { content: text });
    };

    // Fehlerbehandlung
    reader.onerror = (error) => {
      console.error("Error reading file:", error);
    };

    // Lese die Datei als ArrayBuffer (um die Byte-Daten zu bekommen)
    reader.readAsArrayBuffer(file);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("tbody", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, [
            _createElementVNode("button", { onClick: loadData }, "Daten laden")
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("td", null, "Daten vom Server laden und anzeigen", -1))
        ]),
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, [
            _createElementVNode("input", {
              type: "file",
              onChange: onFileChange
            }, null, 32)
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("td", null, "Daten hochladen", -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (serverBankData.value && serverBankData.value.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[2] || (_cache[2] = [
            _createStaticVNode("<div style=\"width:100px;text-align:left;\">id</div><div style=\"width:140px;text-align:left;\">BuchungsId</div><div style=\"width:150px;text-align:left;\">Wertstellung</div><div style=\"width:600px;text-align:left;\">Name</div><div style=\"width:100px;text-align:right;\">Betrag</div><div style=\"width:400px;text-align:right;\">Verwendungszweck</div><div style=\"width:400px;text-align:center;\">Mieter</div>", 7)
          ])))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(serverBankData.value, (bankData) => {
        return (_openBlock(), _createElementBlock("div", {
          key: bankData.id
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(bankData.id), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(bankData.bookingID), 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(formatDate(
                bankData.valueDate ??
                  bankData.bookingDate ??
                  bankData.bookingID?.substr(4, 2) +
                    ("." + bankData.bookingID?.substr(6, 2)) +
                    ("." + bankData.bookingID?.substr(0, 4))
              )), 1),
            _createElementVNode("div", _hoisted_9, _toDisplayString(bankData.name1), 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(bankData.amount) + "€ ", 1),
            _createElementVNode("div", _hoisted_11, _toDisplayString(bankData.purpose), 1),
            _createElementVNode("div", _hoisted_12, [
              _withDirectives(_createElementVNode("select", {
                id: `renter_for_${bankData.id}`,
                "onUpdate:modelValue": ($event: any) => ((bankData.renter) = $event)
              }, [
                _cache[3] || (_cache[3] = _createElementVNode("option", { value: undefined }, null, -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(renter.value, (current) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: current.id,
                    value: current.id
                  }, _toDisplayString(current.forename), 9, _hoisted_14))
                }), 128))
              ], 8, _hoisted_13), [
                [_vModelSelect, bankData.renter]
              ]),
              _createElementVNode("button", {
                onClick: 
                () => {
                  assignRenter(bankData);
                }
              
              }, " Zuordnen ", 8, _hoisted_15)
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}
}

})